function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/lm/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RLogisticsManager = [
  {
    path: '/logistics-manager/dashboard',
    component: view('Dashboard'),
    name: 'lm.dashboard',
    authorizedRole: 'lm'
  },
  {
    path: '/logistics-manager/purchase-orders/approvals/:approver?/:status?',
    component: view('approvals/Base'),
    name: 'lm.purchase-orders.approvals',
    authorizedRole: 'lm',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Approvals',
          active: true
        }
      ]
    }
  }
]

export default RLogisticsManager
