function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RUser = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    authorizedRole: 'user'
  },
  {
    path: '/user/purchase-orders/receiving/:receiver?/:status?',
    component: view('receiving/Base'),
    name: 'user.purchase-orders.receiving',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Purchase Orders'
        },
        {
          text: 'Receiving',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/purchase-orders/requests/:requester?/:status?',
    component: view('requests/Base'),
    name: 'user.purchase-orders.requests',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Purchase Orders'
        },
        {
          text: 'Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/purchase-orders/approvals/:approver?/:status?',
    component: view('approvals/Base'),
    name: 'user.purchase-orders.approvals',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Purchase Orders'
        },
        {
          text: 'Approvals',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/sap/posting/:poster?/:status?',
    component: view('posting/Base'),
    name: 'user.sap.posting',
    authorizedRole: 'user',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'SAP'
        },
        {
          text: 'Posting',
          active: true
        }
      ]
    }
  }
]

export default RUser
