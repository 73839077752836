import ApiService from '@/services/core/api.service'

const ClosingPendingService = {
  async get (payload = {}) {
    return ApiService.getObject('lo/delivery-closings', payload)
  },

  async histories (payload = {}) {
    return ApiService.getObject('lo/reports/delivery-closing-histories', payload)
  },

  async show (id) {
    return ApiService.get(`lo/delivery-closings/${id}`)
  },

  async post (payload = {}) {
    return ApiService.post('lo/delivery-closings', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`lo/delivery-closings/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`lo/delivery-closings/${payload.id}`, {
      data: payload
    })
  }
}

export default ClosingPendingService
