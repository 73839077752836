import { ToWords } from 'to-words'
import MOMENT from 'moment'
import NUMERAL from 'numeral'

export default {

  methods: {
    dateFormatter (value) {
      if (value) {
        return MOMENT(value).format('Y-MM-DD')
      }
      return null
    },

    dateLongFormatter (value) {
      if (value) {
        return MOMENT(value).format('MMMM DD, YYYY')
      }
      return null
    },

    dateShortFormatter (value) {
      if (value) {
        return MOMENT(value).format('MMM DD, YYYY')
      }
      return null
    },

    dateTimeFormatter (value) {
      if (value) {
        return MOMENT(value).format('Y-MM-DD HH:mm:ss')
      }
      return null
    },

    dateTimeShortFormatter (value) {
      if (value) {
        return MOMENT(value).format('MMM DD, YYYY @ LT')
      }
      return null
    },

    dateTimeShortMilitaryFormatter (value) {
      if (value) {
        return MOMENT(value).format('MMM DD, YYYY @ HH:mm')
      }
      return null
    },

    dateTimeLongFormatter (value) {
      if (value) {
        return MOMENT(value).format('MMMM DD, YYYY @ LT')
      }
      return null
    },

    dateTimeLocalFormatter (value) {
      if (value) {
        return MOMENT(value).format('YYYY-MM-DDTHH:mm')
      }
      return null
    },

    numberFormatter (value) {
      if (value) {
        return NUMERAL(value).format('0,0.00')
      }
      return null
    },

    numberToWords (value) {
      const number = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Peso',
            plural: 'Pesos',
            symbol: '',
            fractionalUnit: {
              name: 'Cent',
              plural: 'Cents',
              symbol: ''
            }
          }
        }
      })
      return number.convert(value)
    },

    yesOrNo (value) {
      if (value !== null || value !== '') {
        return Number(value) === 1 ? 'Yes' : 'No'
      }

      return null
    },

    objectToUrl (payload, includeNoValues = true) {
      const payloadKeys = Object.keys(payload)

      if (payloadKeys.length <= 0) {
        return ''
      }

      return payloadKeys.map(
        payloadKey => {
          if (typeof payload[payloadKey] === 'undefined' || payload[payloadKey] === 'null' || payload[payloadKey] === null) {
            return includeNoValues ? `${payloadKey}=` : null
          }

          if (Array.isArray(payload[payloadKey]) > 0) {
            return this.arrayToUrlLists(
              Array.from(payload[payloadKey]),
              payloadKey
            )
          }

          return `${payloadKey}=${payload[payloadKey]}`
        }
      ).filter(value => value).join('&').toString()
    },

    arrayToUrlLists (values, payloadKey = 'lists') {
      if (Array.isArray(values) <= 0) {
        return ''
      }

      return values.filter(value => value).map(
        value => `${payloadKey}[]=${value}`
      ).join('&').toString()
    },

    approvalProgress (_val, _key, { stage, approvals }) {
      if (approvals && stage) {
        const approver = approvals.find(
          approver => Number(approver.stage) === Number(stage)
        )
        if (approver) {
          return {
            approver_label: approver.approver_action,
            count: approvals.filter(
              approver => [
                'Approved',
                'Dispatched',
                'Scheduled',
                'Posted',
                'Received',
                'Closed'
              ].includes(approver.status)
            ).length
          }
        }
      }
      return {
        approver_label: null,
        count: 0
      }
    }
  }
}
