import ApiService from '@/services/core/api.service'

const WarehouseService = {
  async get (payload = {}) {
    return ApiService.getObject('admin/warehouses', payload)
  },

  async import (payload = {}) {
    return ApiService.post('admin/utilities/import-warehouses', payload)
  },

  async sync (payload = {}) {
    return ApiService.post('admin/utilities/sync-warehouses', payload)
  },

  async post (payload = {}) {
    return ApiService.post('admin/warehouses', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`admin/warehouses/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`admin/warehouses/${payload.id}`, {
      data: payload
    })
  }
}

export default WarehouseService
