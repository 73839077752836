import store from '@/store'

export default async (to, _from, next) => {
  const user = store.getters['auth/user']

  if (user.role !== 'user') {
    next({ name: `${user.role}.dashboard` })
  } else {
    const cannotStockOut = Number(user.allow_out) === 0 && [
      'user.purchase-orders.approvals',
      'user.sap',
      'user.sap.posting'
    ].includes(to.name)

    const cannotStockIn = Number(user.allow_in) === 0 && [
      'user.purchase-orders.requests',
      'user.purchase-orders.receiving'
    ].includes(to.name)

    if (cannotStockOut || cannotStockIn) {
      next({ name: `${user.role}.dashboard` })
    } else {
      next()
    }
  }
}
