import ApiService from '@/services/core/api.service'

const ApprovalService = {
  async get (payload = {}) {
    return ApiService.getObject('user/transfer-approvals', payload)
  },

  async histories (payload = {}) {
    return ApiService.getObject('user/reports/request-approval-histories', payload)
  },

  async show (id) {
    return ApiService.get(`user/transfer-approvals/${id}`)
  },

  async post (payload = {}) {
    return ApiService.post('user/transfer-approvals', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`user/transfer-approvals/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`user/transfer-approvals/${payload.id}`, {
      data: payload
    })
  }
}

export default ApprovalService
