import ApiService from '@/services/core/api.service'

const AuthService = {
  async signIn (payload = {}) {
    return ApiService.post('auth/login', payload)
  },

  async getCurrentUser () {
    return ApiService.get('auth/me')
  },

  async changePassword (payload = {}) {
    return ApiService.post('auth/password', payload)
  },

  async logout () {
    return ApiService.post('auth/logout')
  }
}

export default AuthService
