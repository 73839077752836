import ApiService from '@/services/core/api.service'

const ProductService = {
  async get (payload = {}) {
    return ApiService.getObject('admin/products', payload)
  },

  async import (payload = {}) {
    return ApiService.post('admin/utilities/import-products', payload)
  },

  async sync (payload = {}) {
    return ApiService.post('admin/utilities/sync-products', payload)
  },

  async post (payload = {}) {
    return ApiService.post('admin/products', payload)
  },

  async put (payload = {}) {
    return ApiService.put(`admin/products/${payload.id}`, payload)
  },

  async delete (payload = {}) {
    return ApiService.delete(`admin/products/${payload.id}`, {
      data: payload
    })
  }
}

export default ProductService
