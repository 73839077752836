import ApiService from '@/services/core/api.service'

const ListService = {

  async getRoles (payload = {}) {
    return ApiService.getObject('shared/list/roles', payload)
  },

  async getWarehouses (payload = {}) {
    return ApiService.getObject('shared/list/warehouses', payload)
  },

  async getLocations (payload = {}) {
    return ApiService.getObject('shared/list/locations', payload)
  },

  async getVehicles (payload = {}) {
    return ApiService.getObject('shared/list/vehicles', payload)
  },

  async getDrivers (payload = {}) {
    return ApiService.getObject('shared/list/drivers', payload)
  },

  async getGroups (payload = {}) {
    return ApiService.getObject('shared/list/groups', payload)
  },

  async getCategories (payload = {}) {
    return ApiService.getObject('shared/list/categories', payload)
  },

  async getProducts (payload = {}) {
    return ApiService.getObject('shared/list/products', payload)
  },

  async getStocks (payload = {}) {
    return ApiService.getObject('shared/inquiry/product-stocks', payload)
  },

  async getSystemOptions (payload = {}) {
    return ApiService.getObject('shared/list/system-options', payload)
  }
}

export default ListService
