import ApiService from '@/services/core/api.service'

const CategoryService = {
  async get (payload = {}) {
    return ApiService.getObject('admin/categories', payload)
  },

  async import (payload = {}) {
    return ApiService.post('admin/utilities/import-categories', payload)
  },

  async sync (payload = {}) {
    return ApiService.post('admin/utilities/sync-categories', payload)
  },

  async post (payload = {}) {
    return ApiService.post('admin/categories', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`admin/categories/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`admin/categories/${payload.id}`, {
      data: payload
    })
  }
}

export default CategoryService
