import ApiService from '@/services/core/api.service'

const ReceivingService = {
  async get (payload = {}) {
    return ApiService.getObject('user/transfer-receivings', payload)
  },

  async histories (payload = {}) {
    return ApiService.getObject('user/reports/transfer-receiving-histories', payload)
  },

  async exports (payload = {}) {
    return ApiService.getObject('user/exports/transfer-receivings', payload)
  },

  async show (id) {
    return ApiService.get(`user/transfer-receivings/${id}`)
  },

  async post (payload = {}) {
    return ApiService.post('user/transfer-receivings', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`user/transfer-receivings/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`user/transfer-receivings/${payload.id}`, {
      data: payload
    })
  }
}

export default ReceivingService
