import ApiService from '@/services/core/api.service'

const LaterPostingService = {
  async get (payload = {}) {
    return ApiService.getObject('user/later-postings', payload)
  },

  async show (id) {
    return ApiService.get(`user/later-postings/${id}`)
  },

  async post (payload = {}) {
    return ApiService.post('user/later-postings', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`user/later-postings/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`user/later-postings/${payload.id}`, {
      data: payload
    })
  }
}

export default LaterPostingService
