import store from '@/store'

export default async (_to, _from, next) => {
  const user = store.getters['auth/user']
  if (user.role !== 'lm') {
    next({ name: `${user.role}.dashboard` })
  } else {
    next()
  }
}
