import ApiService from '@/services/core/api.service'

const DeliverySchedulingService = {
  async get (payload = {}) {
    return ApiService.getObject('lo/delivery-schedulings', payload)
  },

  async histories (payload = {}) {
    return ApiService.getObject('lo/reports/delivery-scheduling-histories', payload)
  },

  async exports (payload = {}) {
    return ApiService.getObject('lo/exports/delivery-schedulings', payload)
  },

  async show (id) {
    return ApiService.get(`lo/delivery-schedulings/${id}`)
  },

  async post (payload = {}) {
    return ApiService.post('lo/delivery-schedulings', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`lo/delivery-schedulings/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`lo/delivery-schedulings/${payload.id}`, {
      data: payload
    })
  }
}

export default DeliverySchedulingService
