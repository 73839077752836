import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/src/scss/vue-select.scss'

// vSelect.props.components.default = () => ({
//   Deselect: {
//     render: createElement => createElement('span', {
//       class: 'fad fa-close'
//     }),
//     hack: {
//       listeners: {
//         onload: args => {
//           console.log(args)
//         }
//       }
//     }
//   }
// })

Vue.component('v-select', vSelect)
