import ApiService from '@/services/core/api.service'

const PostingServince = {
  async get (payload = {}) {
    return ApiService.getObject('user/transfer-postings', payload)
  },

  async histories (payload = {}) {
    return ApiService.getObject('user/reports/transfer-posting-histories', payload)
  },

  async show (id) {
    return ApiService.get(`user/transfer-postings/${id}`)
  },

  async post (payload = {}) {
    return ApiService.post('user/transfer-postings', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`user/transfer-postings/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`user/transfer-postings/${payload.id}`, {
      data: payload
    })
  }
}

export default PostingServince
