import store from '@/store'
import { SSharedAuth } from '@/services'

/* eslint-disable-next-line */
export default async (_to, _from, next) => {
  if (!store.getters['auth/check'] && store.getters['auth/token']) {
    await SSharedAuth.getCurrentUser().then(({ data }) => {
      store.dispatch('auth/fetchUserSuccess', {
        id: data.id,
        email: data.email,
        role: data.role.code,
        role_name: data.role.name,
        username: data.role.username,
        name: data.name,
        location: data.location?.id || null,
        location_name: data.location?.location_name || null,
        location_code: data.location?.location_code || null,
        allow_in: data.location?.allow_in || null,
        allow_out: data.location?.allow_out || null,
        warehouse: data.location?.warehouse || null,
        prefix: data.location?.prefix || null
      })
    }).catch(() => {
      store.dispatch('auth/fetchUserFailure')
    })
  }

  next()
}
