import ApiService from '@/services/core/api.service'

const UserService = {
  async get (payload = {}) {
    return ApiService.getObject('admin/users', payload)
  },

  async post (payload = {}) {
    return ApiService.post('admin/users', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`admin/users/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`admin/users/${payload.id}`, {
      data: payload
    })
  }
}

export default UserService
