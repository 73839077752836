function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RAdmin = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    authorizedRole: 'admin'
  },
  {
    path: '/admin/accounts/users',
    component: view('accounts/Users'),
    name: 'admin.accounts.users',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Accounts'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/warehouses',
    component: view('maintenances/Warehouses'),
    name: 'admin.maintenances.warehouses',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Warehouses',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/locations',
    component: view('maintenances/Locations'),
    name: 'admin.maintenances.locations',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Locations',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/groups',
    component: view('maintenances/Groups'),
    name: 'admin.maintenances.groups',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Groups',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/categories',
    component: view('maintenances/Categories'),
    name: 'admin.maintenances.categories',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/products',
    component: view('maintenances/Products'),
    name: 'admin.maintenances.products',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Maintenances'
        },
        {
          text: 'Products',
          active: true
        }
      ]
    }
  }
]

export default RAdmin
