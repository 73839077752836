import ApiService from '@/services/core/api.service'

const LocationService = {
  async get (payload = {}) {
    return ApiService.getObject('admin/locations', payload)
  },

  async post (payload = {}) {
    return ApiService.post('admin/locations', payload)
  },

  async put (payload = {}) {
    return ApiService.put(`admin/locations/${payload.id}`, payload)
  },

  async delete (payload = {}) {
    return ApiService.delete(`admin/locations/${payload.id}`, {
      data: payload
    })
  }
}

export default LocationService
