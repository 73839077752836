import RAdmin from './admin.routes'
import RAudit from './audit.routes'
import RLogisticsManager from './lm.routes'
import RLogisticsOfficer from './lo.routes'
import RUser from './user.routes'
import RShared from './shared.routes'

const allRoutes = []

export default allRoutes.concat(
  RAdmin,
  RAudit,
  RShared,
  RLogisticsManager,
  RLogisticsOfficer,
  RUser
)
