import ApiService from '@/services/core/api.service'

const VehicleService = {
  async get (payload = {}) {
    return ApiService.getObject('lo/vehicles', payload)
  },

  async post (payload = {}) {
    return ApiService.post('lo/vehicles', payload)
  },

  async put (payload = { id: null }) {
    return ApiService.put(`lo/vehicles/${payload.id}`, payload)
  },

  async delete (payload = { id: null }) {
    return ApiService.delete(`lo/vehicles/${payload.id}`)
  }
}

export default VehicleService
